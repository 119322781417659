import React from 'react';
import { useTranslation } from 'react-i18next';
import ModalCustom from '@components/UI/ModalCustom';

import { Form, Formik } from 'formik';
import InputCustom from '@components/UI/InputCustom';
import ToastCustom from '@components/UI/ToastCustom';
import FileUploader from '@components/UI/FileUploader';
import { ButtonFirstAction, ButtonSecondAction } from '@components/UI/Buttons';

import congesService from '@data/congesService';

import PropTypes from 'prop-types';

const DocumentationModal = ({
  isModalOpen,
  setIsModalOpen,
  sites,
  preview,
  fetchDocuments,
}) => {
  const { t } = useTranslation();

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const onModalClose = () => {
    toggleModal();
  };

  const handleSubmit = async (values, actions) => {
    try {
      const resultSite = [];
      values.ids.forEach((elem) => resultSite.push(elem.id));

      const data = JSON.stringify({
        name: values.name,
        ids: resultSite,
      });

      const formData = new FormData();
      formData.append('data', data);
      if (values.documentation[0]?.name !== preview.documentation[0]?.name) {
        formData.append('documentation', values.documentation[0]);
      }

      if (values.id) {
        await congesService.post(`/v1/documentation/${values.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        ToastCustom.validated('successStore');
      } else {
        await congesService.post('/v1/documentation', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        ToastCustom.validated('successUpdate');
      }

      fetchDocuments();
    } catch (err) {
      ToastCustom.error();
      console.error(err);
    } finally {
      actions.setSubmitting(false);
      toggleModal();
    }
  };

  return (
    <>
      <ModalCustom
        title={t('addDocument')}
        isOpen={isModalOpen}
        onModalClose={onModalClose}
        customClass={'w-1/2 md:w-[900px]'}
      >
        <div className='w-full mt-4'>
          <Formik
            initialValues={{
              ids: preview.ids ?? [],
              id: preview.id ?? '',
              name: preview.name ?? '',
              documentation: preview.documentation ?? [],
            }}
            onSubmit={handleSubmit}
            validate={(values) => {
              const errors = {};
              if (!values.name) {
                errors.name = t('requiredFields');
              }
              if (!values.ids.length) {
                errors.ids = t('requiredFields');
              }
              if (!values.documentation.length) {
                errors.documentation = t('requiredFields');
              }
              return errors;
            }}
            validateOnChange={false}
            validateOnBlur={false}
          >
            {({ values, handleSubmit, setFieldValue }) => (
              <Form onSubmit={handleSubmit} id='documentation-form'>
                <InputCustom
                  name='name'
                  type='text'
                  label={t('titleDocument') + ' *'}
                />
                <InputCustom
                  name='ids'
                  label={t('concernedSite')}
                  type='reactSelect'
                  placeholder={''}
                  containerClass='my-4'
                  isMulti={true}
                  options={sites}
                />
                <div className=''>
                  <span className='block text-xs font-medium text-gray-700 mb-1'>
                    {t('file')} *
                  </span>
                  <FileUploader
                    type='fileUploader'
                    containerClass='mb-3 pb-4'
                    handleRemoveFile={() => setFieldValue('documentation', [])}
                    files={values.documentation}
                    setFiles={(e) => setFieldValue('documentation', e)}
                    name='documentation'
                    isMultiple={false}
                  />
                </div>
                <div className='flex items-center justify-end gap-x-3'>
                  <ButtonSecondAction onClick={toggleModal} type='button'>
                    {t('cancel')}
                  </ButtonSecondAction>
                  <ButtonFirstAction type='submit'>
                    {t('continue')}
                  </ButtonFirstAction>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </ModalCustom>
    </>
  );
};

export default DocumentationModal;

DocumentationModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
};
